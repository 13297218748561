
@import "reports";
@import "calendar";

form.form-horizontal .form-group .radio {
    padding: 5px 10px 10px 5px;
}

form.form-horizontal .form-group .radio:first-child {
    padding: 10px 10px 10px 5px;
}

.label-notify {
    background-color: #ffcb00;
    color: #1f1f1f;
}

.label-light-success {
    background-color: #fff;
    color: #33b795;
    border: 1px solid #33b795;
}

.label-light-info {
    background-color: #fff;
    color: #619DD0;
    border: 1px solid #75ACDC;
}

.label-light-danger {
    background-color: #fff;
    color: #ff6120;
    border: 1px solid #ff6120;
}

.label {
    font-weight: normal;
}

.grafana-row {
    margin-bottom: 10px;
}    

.grafana-iframe {
    border: 0;
    height: 260px;
}

/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/prompt/Prompt-Regular.woff2') format('woff2');
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
    /* unicode-range: U+0E00-U+0E7F; */
}

body {
    font-family: 'Prompt', Helvetica Neue, Arial, sans-serif;
}

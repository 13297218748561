
.audit-reports {

    section {
        margin: 5px 0;
    }

    #histogram-canvas {
        height: 300px;
        background: #fff;
    }

    #pie-charts-canvas {
        height: 350px;
        background: #fff;
    }

    .axis path,
    .axis line {
        fill: none;
        stroke: #ddd;
        shape-rendering: crispEdges;
    }

    .axis text {
        font-family: sans-serif;
        font-size: 11px;
    }

    .dot {
        stroke: #000;
    }

    .table > thead > tr > th {
        border-bottom: 1px solid #DDD;
        color: #111;
        font-weight: normal;
    }

    .table.dataTable.no-footer {
        border-bottom: 1px solid #BDBDBD;
    }

    .panel .panel-heading {
        color: #999;
    }

    .btn.btn-flat {
        background: #FFF;
        text-shadow: 0 0 0;
        box-shadow: 0 0 0;
        border: 1px solid #DDD;
        color: #909090;
        border-radius: 0px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 400;
        i {
            margin-right: 3px;
        }
    }

    .btn.btn-flat:hover {
        border-color: rgb(0, 173, 255);
    }

    .btn.btn-flat.btn-active {
        background: rgb(0, 173, 255);
        color: rgba(12, 96, 142, 1);
        color: #fff;
        border-color: rgb(0, 173, 255);
        border-color: #fff;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        box-shadow: 1px 1px 1px rgba(173, 173, 173, 0.43);
        border: 0;
    } 

    .btn.btn-flat.btn-red {
        color: #fff;
        background: #FF5151;
        border-color: #fff;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        box-shadow: 1px 1px 1px rgba(173, 173, 173, 0.43);
        text-transform: none;
        border: 0;
    }

    /** Custom datepicker styles **/

    #datepicker .description {
        margin-left: 0;
    }

    #datepicker .datepicker-description {
        color: #555;
        margin-top: 10px; 
        margin-bottom: 15px;
    }

    #datepicker .datepicker-button {
        background: #eee;
        color: #333;
        margin: 0;
        padding: 3px 10px;
        width: 95px;
        font-weight: bold;
        border: 1px solid #ccc;
    }

    /** Custom for reports panels **/

    .panel {
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }

    .panel-default {
        border-radius: 0;
        border: 1px solid #ddd;
        .panel-heading {
            border-radius: 0;
            .panel-title {
                font-size: 12px;
                font-weight: bold;
                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .panel-deepblue {
        border-color: #7495bf;
        border-width: 2px;
        a {
            color: #7495bf;
        }
        .panel-heading {
            color: #fff;
            background-color: #7495bf;
            border-color: #7495bf;
        }
    }

    .panel-deepred {
        border-color: #bf7474;
        border-width: 2px;
        a {
            color: #bf7474;
        }
        .panel-heading {
            color: #fff;
            background-color: #bf7474;
            border-color: #bf7474;
        }
    }

    /** Custom for reports data table **/

    .dataTables_wrapper {
        select, .dataTables_paginate .paginate_button.current, .dataTables_paginate .paginate_button.current:hover {
            background: white;
            border-color: #ddd;
        }
        .dataTables_paginate .paginate_button:hover {
            background: #333;
        }
    }

    span.btn-link, span.btn-link:hover {
        cursor: default;
        text-decoration: none;
    }

    input.btn-link, input.btn-link:hover, input.btn-link:focus {
        cursor: pointer;
        text-decoration: none;
    }

}

.d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 2px;
    z-index: 9999;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
}
/* Style northward tooltips differently */
.d3-tip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
}

.label {
    &.col-priority {
        background-color: #fff;
        padding: 4px 13;
        display: block;
        width: 80px;
        text-transform: capitalize;
    }
    &.col-priority-emerg {
        color: #FFFFFF;
        border: 1px solid #D63F3F;
        background: #D63F3F;
    }
    &.col-priority-alert {
        color: #FFFFFF;
        border: 1px solid #F95656;
        background: #F95656;
    }
    &.col-priority-crit {
        color: #FFF;
        border: 1px solid #FF6E00;
        background: #FF7E4D;
    }
    &.col-priority-err {
        color: #FFFFFF;
        border: 1px solid #FFAC45;
        background: #FFAC45;
    }
    &.col-priority-warning {
        color: #FFFFFF;
        border: 1px solid #ECC500;
        background: #FFD400;
    }
    &.col-priority-notice {
        color: #00BF61;
        border: 1px solid #00BF61;
    }
    &.col-priority-debug {
        color: #00BFCC;
        border: 1px solid #00BFCC;
    }
    &.col-priority-info {
        color: #888888;
        border: 1px solid #C5C5C5;
    }
}

.audit-reports {
  /** Custom datepicker styles **/
  /** Custom for reports panels **/
  /** Custom for reports data table **/
}

.audit-reports section {
  margin: 5px 0;
}

.audit-reports #histogram-canvas {
  height: 300px;
  background: #fff;
}

.audit-reports #pie-charts-canvas {
  height: 350px;
  background: #fff;
}

.audit-reports .axis path,
.audit-reports .axis line {
  fill: none;
  stroke: #ddd;
  shape-rendering: crispEdges;
}

.audit-reports .axis text {
  font-family: sans-serif;
  font-size: 11px;
}

.audit-reports .dot {
  stroke: #000;
}

.audit-reports .table > thead > tr > th {
  border-bottom: 1px solid #DDD;
  color: #111;
  font-weight: normal;
}

.audit-reports .table.dataTable.no-footer {
  border-bottom: 1px solid #BDBDBD;
}

.audit-reports .panel .panel-heading {
  color: #999;
}

.audit-reports .btn.btn-flat {
  background: #FFF;
  text-shadow: 0 0 0;
  box-shadow: 0 0 0;
  border: 1px solid #DDD;
  color: #909090;
  border-radius: 0px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
}

.audit-reports .btn.btn-flat i {
  margin-right: 3px;
}

.audit-reports .btn.btn-flat:hover {
  border-color: #00adff;
}

.audit-reports .btn.btn-flat.btn-active {
  background: #00adff;
  color: #0c608e;
  color: #fff;
  border-color: #00adff;
  border-color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px rgba(173, 173, 173, 0.43);
  border: 0;
}

.audit-reports .btn.btn-flat.btn-red {
  color: #fff;
  background: #FF5151;
  border-color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px rgba(173, 173, 173, 0.43);
  text-transform: none;
  border: 0;
}

.audit-reports #datepicker .description {
  margin-left: 0;
}

.audit-reports #datepicker .datepicker-description {
  color: #555;
  margin-top: 10px;
  margin-bottom: 15px;
}

.audit-reports #datepicker .datepicker-button {
  background: #eee;
  color: #333;
  margin: 0;
  padding: 3px 10px;
  width: 95px;
  font-weight: bold;
  border: 1px solid #ccc;
}

.audit-reports .panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.audit-reports .panel-default {
  border-radius: 0;
  border: 1px solid #ddd;
}

.audit-reports .panel-default .panel-heading {
  border-radius: 0;
}

.audit-reports .panel-default .panel-heading .panel-title {
  font-size: 12px;
  font-weight: bold;
}

.audit-reports .panel-default .panel-heading .panel-title i {
  margin-right: 5px;
}

.audit-reports .panel-deepblue {
  border-color: #7495bf;
  border-width: 2px;
}

.audit-reports .panel-deepblue a {
  color: #7495bf;
}

.audit-reports .panel-deepblue .panel-heading {
  color: #fff;
  background-color: #7495bf;
  border-color: #7495bf;
}

.audit-reports .panel-deepred {
  border-color: #bf7474;
  border-width: 2px;
}

.audit-reports .panel-deepred a {
  color: #bf7474;
}

.audit-reports .panel-deepred .panel-heading {
  color: #fff;
  background-color: #bf7474;
  border-color: #bf7474;
}

.audit-reports .dataTables_wrapper select, .audit-reports .dataTables_wrapper .dataTables_paginate .paginate_button.current, .audit-reports .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: white;
  border-color: #ddd;
}

.audit-reports .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #333;
}

.audit-reports span.btn-link, .audit-reports span.btn-link:hover {
  cursor: default;
  text-decoration: none;
}

.audit-reports input.btn-link, .audit-reports input.btn-link:hover, .audit-reports input.btn-link:focus {
  cursor: pointer;
  text-decoration: none;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  z-index: 9999;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}

.label.col-priority {
  background-color: #fff;
  padding: 4px 13;
  display: block;
  width: 80px;
  text-transform: capitalize;
}

.label.col-priority-emerg {
  color: #FFFFFF;
  border: 1px solid #D63F3F;
  background: #D63F3F;
}

.label.col-priority-alert {
  color: #FFFFFF;
  border: 1px solid #F95656;
  background: #F95656;
}

.label.col-priority-crit {
  color: #FFF;
  border: 1px solid #FF6E00;
  background: #FF7E4D;
}

.label.col-priority-err {
  color: #FFFFFF;
  border: 1px solid #FFAC45;
  background: #FFAC45;
}

.label.col-priority-warning {
  color: #FFFFFF;
  border: 1px solid #ECC500;
  background: #FFD400;
}

.label.col-priority-notice {
  color: #00BF61;
  border: 1px solid #00BF61;
}

.label.col-priority-debug {
  color: #00BFCC;
  border: 1px solid #00BFCC;
}

.label.col-priority-info {
  color: #888888;
  border: 1px solid #C5C5C5;
}

#calendar .fc-event {
  text-align: center;
  border: 0;
  background: transparent;
}

@media only screen and (min-width: 996px) {
  #calendar .fc-event {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1200px) {
  #calendar .fc-event {
    font-size: 1.8em;
  }
}

#calendar a {
  cursor: pointer;
}

#calendar .fc-event, #calendar .fc-event:hover, #calendar .ui-widget .fc-event {
  color: #777;
}

#calendar .fc-event {
  border: none;
  background-color: #fff;
}

#calendar .fc-event .fc-content {
  border: 0px;
  background-color: rgba(228, 228, 228, 0.35);
  padding: 35px 0;
  color: #444;
}

#calendar .bg-calendar {
  background: rgba(228, 228, 228, 0.35);
}

#calendar .fc-content:before {
  content: "Usage";
  display: block;
  margin-bottom: 15px;
  font-size: 0.8em;
}

#calendar .fc-title {
  font-weight: 700;
}

#calendar th.fc-day-header {
  background: #F1F1F1;
}

#calendar h2 {
  padding: 20px 0;
}

#calendar > .fc-toolbar {
  border-bottom: 1px solid #ECECEC;
  margin-bottom: 30px;
}

#calendar > .fc-toolbar > .fc-right {
  margin-top: 20px;
}

form.form-horizontal .form-group .radio {
  padding: 5px 10px 10px 5px;
}

form.form-horizontal .form-group .radio:first-child {
  padding: 10px 10px 10px 5px;
}

.label-notify {
  background-color: #ffcb00;
  color: #1f1f1f;
}

.label-light-success {
  background-color: #fff;
  color: #33b795;
  border: 1px solid #33b795;
}

.label-light-info {
  background-color: #fff;
  color: #619DD0;
  border: 1px solid #75ACDC;
}

.label-light-danger {
  background-color: #fff;
  color: #ff6120;
  border: 1px solid #ff6120;
}

.label {
  font-weight: normal;
}

.grafana-row {
  margin-bottom: 10px;
}

.grafana-iframe {
  border: 0;
  height: 260px;
}

/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/prompt/Prompt-Regular.woff2") format("woff2");
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
  /* unicode-range: U+0E00-U+0E7F; */
}

body {
  font-family: 'Prompt', Helvetica Neue, Arial, sans-serif;
}

$event_colrs: rgba(228, 228, 228, 0.35);

#calendar {
    .fc-event {
        text-align: center;
        border: 0;
        background: transparent;
    }

    @media only screen and (min-width: 996px) {
        .fc-event {
            font-size: 1.2em;
        }
    }

    @media only screen and (min-width: 1200px) {
        .fc-event {
            font-size: 1.8em;
        }
    }

    a {
        cursor: pointer;
    }

    .fc-event, .fc-event:hover, .ui-widget .fc-event {
        color: #777;
    }

    .fc-event {
        border: none;
        background-color: #fff;
    }

    .fc-event .fc-content {
        border: 0px;
        background-color: $event_colrs;
        padding: 35px 0;
        color: #444;
    }

    .bg-calendar {
        background: $event_colrs;
    }

    .fc-content:before {
        content: "Usage";
        display: block;
        margin-bottom: 15px;
        font-size: 0.8em;
    }

    .fc-title {
        font-weight: 700;
    }

    th.fc-day-header {
        background: #F1F1F1;
    }

    h2 {
        padding: 20px 0;
    }

    > .fc-toolbar {
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 30px;
        > .fc-right {
            margin-top: 20px;
        }
    }
}
